<template>
  <div class="userEditor">
    <h2>User Settings</h2>
    <div class="mt-1 flex items-center">
      email
      <input
          type="text"
          autocomplete="email"
          :value="user.email"
          class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>
    <div class="my-4 w-full flex flex-row items-center justify-between">
      <div class="flex items-center ">
        <input
            type="checkbox"
            :checked="user.isRegistered"
            :disabled="true"
            class="mt-1 mr-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
        is registered
      </div>
      <div class="flex items-center ">
        <input
            type="checkbox"
            :checked="user.isEditor || user.isAdmin"
            class="mt-1 mr-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
        is editor
      </div>
      <div class="flex items-center ">
        <input
            type="checkbox"
            :checked="user.isAdmin"
            class="mt-1 mr-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
        is admin
      </div>

    </div>
    <div>
      <div class="flex items-center ">
        <button
            class="rounded w-72 mt-4 py-2 bg-red-900 text-white"
            @click="onDeleteUser"
        >DELETE USER
        </button>
      </div>

    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {UNINVITE_USER} from "@/store/operations";

export default {
  name: "UserEditor",
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const onDeleteUser = () => store.dispatch(UNINVITE_USER, props.user.email)

    return {
      onDeleteUser
    }
  }
}
</script>

<style scoped>

</style>
